import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { AppService } from 'src/app/service/app.service';
import { config } from 'src/app/valueObject/config';
import { User } from 'src/app/valueObject/user';
import { catchError } from 'rxjs/operators';
import { throwError, Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/service/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { extend } from 'jquery';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { UserControlService } from 'src/app/service/user-control.service';
import { DatatableService } from 'src/app/service/datatable.service';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;
@Component({
  selector: 'app-useraccesslist',
  templateUrl: './useraccesslist.component.html',
  styleUrls: ['./useraccesslist.component.css']
})
export class UseraccesslistComponent implements OnInit, OnDestroy, AfterViewInit {

  config: any = config;
  users: User[] = [];
  user: any = {};
  dtOptions: any;
  dtTrigger: Subject<any> = new Subject<any>();
  viewUser: any = {};
  searchUserForm: FormGroup;
  entityTypeData: any;
  keyword: string = "name";
  userDetails: any = {};
  userControlId: string = '';
  showDataTable: boolean = true;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  constructor(private appService: AppService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private dtService: DatatableService,
    private userDetailsService: UserDetailsService,
    private userControlService: UserControlService,
    private route: ActivatedRoute) { }

  get f() { return this.searchUserForm.controls; }

  ngOnInit(): void {
    this.titleService.setTitle('Access Management');
    this.userControlId = this.userControlService.getUserControlId();
    this.user = this.userControlId ? this.userControlService.getUserControlDetails() : this.userDetailsService.getUserDetails();
    this.entityTypeData = this.config.data.entityTypeData;
    this.userDetails = this.userControlId ? this.userControlService.getUserControlDetails() : this.userDetailsService.getUserDetails();
    $('[data-toggle="popover"]').popover();
  }

  ngAfterViewInit() {
    this.getUserList();
    // $('.selectpicker').selectpicker();
  }

  getUserList() {
    this.spinner.show();
    this.showDataTable = false;
    this.appService.getUserList(this.userControlId).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.users = [];
        this.dtService.setDatatable("userAccessList", 6, 10, [10, 25, 50, 100]);
        this.showDataTable = true;
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response.statusCode == 200) {
          this.users = response.data;
          this.userService.addUsers(this.users);
          this.dtService.setDatatable("userAccessList", 6, 10, [10, 25, 50, 100]);
          this.showDataTable = true;
        } else {
          this.users = [];
          this.dtService.setDatatable("userAccessList", 6, 10, [10, 25, 50, 100]);
          this.showDataTable = true;
          this.toastr.error(response.message);
        }
      }
    );
  }

  // setDatatable() {
  //   $("#userAccessList").DataTable().destroy();
  //   setTimeout(() => {
  //     $("#userAccessList").DataTable({
  //       responsive: true,
  //       processing: true,
  //       orderCellsTop: true,
  //       dom: "Blrtip",
  //       buttons: [{
  //         extend: 'copy',
  //         titleAttr: 'Copy',
  //         exportOptions: {
  //           columns: [0, 1, 2, 3, 4, 5],
  //         }
  //       }, {
  //         extend: 'excel',
  //         titleAttr: 'Export to excel',
  //         exportOptions: {
  //           columns: [0, 1, 2, 3, 4, 5],
  //         }
  //       }, {
  //         extend: 'pdf',
  //         orientation: 'landscape',
  //         pageSize: 'A4',
  //         titleAttr: 'Download as PDF',
  //         exportOptions: {
  //           columns: [0, 1, 2, 3, 4, 5],
  //         }
  //       }],
  //       initComplete: function () {
  //         this.api().columns().every(function () {
  //           $('#userAccessList .column-search input').val('');
  //           $('#userAccessList .column-search select').val('.*');
  //           $('#userAccessList .column-search').on('keyup change', 'input', function () {
  //             $('#userAccessList').DataTable()
  //               .column($(this).parent().index())
  //               .search(this.value)
  //               .draw();
  //           });
  //           $('#userAccessList .column-search').on('keyup change', 'select', function () {
  //             $('#userAccessList').DataTable()
  //               .column($(this).parent().index())
  //               .search("^" + this.value + "$", true, false, false)
  //               .draw();
  //           });
  //         })
  //       }
  //     });
  //     this.cdref.detectChanges();
  //   }, 1);
  // }

  viewUserData(id: number) {
    this.viewUser = this.users.filter(user => user.userId == id)[0];
  }

  editUserData(id: number) {
    this.router.navigate(['accessmapping'], { "queryParams": { "id": id }, relativeTo: this.route, skipLocationChange: true });
  }

  clearSearch() {
    this.dtService.setDatatable("userAccessList", 6, 10, [10, 25, 50, 100]);
  }

  reset() {
    this.searchUserForm.reset();
  }

  ngOnDestroy(): void {
    this.dtTrigger?.unsubscribe();
  }
}
