
<header class="fixed-top">
    <div class="col-lg-12 ">
        <div class="container-fluid  ">
            <div class="row justify-content-between align-items-center pb-1   ">
                
                <div class="col-lg-3 col-md-3 col-3 ">
                    <div class="row">
                        <!-- <div class="col-lg-2"> <button id="sidebarCollapse" class="pl-2 pr-2 menuclick" type="button"
                                (click)="clicktoggle()">
                                <i class="fa fa-bars" [ngClass]="fullscreen ? '' : 'hide'" aria-hidden="true"></i>
                                <svg [ngClass]="fullscreen ? 'hide' : ''" xmlns="http://www.w3.org/2000/svg" width="18"
                                    height="18" fill="currentColor" class="bi bi-x-lg i-close" viewBox="0 0 16 16">
                                    <path
                                        d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                                </svg>
                                <span class="sr-only"> click here</span>
                            </button>
                        </div> -->
                        <div class="col-lg-2 dropdown">
                            <button id="sidebarCollapse" class="pl-2 pr-2 menuclick dropdown-toggle" type="button"
                                data-toggle="dropdown" (click)="clicktoggle()">
                                <i class="fa fa-bars" aria-hidden="true" [ngClass]="fullscreen ? '' : 'hide'"></i>
                                <svg xmlns="http://www.w3.org/2000/svg" [ngClass]="fullscreen ? 'hide' : ''" width="18"
                                    height="18" fill="currentColor" class="bi bi-x-lg i-close" viewBox="0 0 16 16">
                                    <path
                                        d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                                </svg>
                                
                            </button>
                            <div class="dropdown-menu shadow" hidden>
                                <div class="d-flex menuDropdown">
                                    <div class="col-lg-12">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-4" *ngFor="let menu of menuListFinal">
                                                <div class="card  card-container mt card shadow1">
                                                    <img src='{{"assets/images/New_images/"+menu.menuCode+".png"}}'
                                                        alt="Avatar" class="image ">
                                                    <div class="card-body">
                                                        <a *ngIf="menu?.sappType=='I'" role="button"
                                                            (click)="hideSidebar()" [routerLink]="menu.menuCode">
                                                            <h2 class="card-title">{{menu.menuName}}</h2>
                                                        </a>
                                                        <a *ngIf="menu?.sappType=='E'" role="button" href="#"
                                                            (click)="hideSidebar(); accessApplication(menu)">
                                                            <h2 class="card-title">{{menu.menuName}}</h2>
                                                        </a>
                                                    </div>
                                                    <div class="overlay" *ngIf="menu?.userMenusImpls?.length> 0">
                                                        <ul>
                                                            <li class="dashFont"
                                                                *ngFor="let sublist of menu.userMenusImpls; let i = index">
                                                                <a *ngIf="sublist?.sappType=='I'"
                                                                    (click)="hideSidebar()"
                                                                    [routerLink]="'/'+menu.menuCode+'/'+sublist.menuCode">{{sublist.menuName}}
                                                                </a>
                                                                <a *ngIf="sublist?.sappType=='E'" href="#"
                                                                    (click)="hideSidebar(); accessApplication(sublist)"
                                                                    class="dashboard-nav-child">{{sublist.menuName}}
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-lg-3">
                                                <div class="card  card-container mt card shadow1">
                                                    <img src="../../../assets/images/New_images/ENIT.png" alt="Avatar"
                                                        class="image ">
                                                    <div class="card-body">
                                                        <h3 class="card-title">ENIT</h3>
                                                    </div>
                                                    <div class="overlay">
                                                        <ul>
                                                            <li class="dashFont"> <a routerLink="#">Trade</a></li>
                                                            <li class="dashFont"><a routerLink="#">Compliance</a></li>
                                                            <li class="dashFont"><a routerLink="#">System Audit</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="card  card-container mt card shadow1">
                                                    <img src="../../../assets/images/New_images/Extranet.png"
                                                        alt="Avatar" class="image">
                                                    <div class="card-body">
                                                        <h3 class="card-title">Extranet</h3>
                                                    </div>
                                                    <div class="overlay">
                                                        <ul>
                                                            <li class="dashFont"> <a routerLink="#">Member</a></li>
                                                            <li class="dashFont"><a routerLink="#">Common</a></li>
                                                            <li class="dashFont"><a routerLink="#">Circular</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="card  card-container mt card shadow1">
                                                    <img src="../../../assets/images/New_images/Inspection.png"
                                                        alt="Avatar" class="image">
                                                    <div class="card-body">
                                                        <h3 class="card-title">INSPECTION</h3>
                                                    </div>
                                                    <div class="overlay">
                                                        <ul>
                                                            <li class="dashFont"> <a routerLink="#">Inspection</a> </li>
                                                            <li class="dashFont"><a routerLink="#">Inspection - Internal Audit</a> </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="card card-container mt card shadow1">
                                                    <img src="../../../assets/images/New_images/UCI.png" alt="Avatar"
                                                        class="image">
                                                    <div class="card-body">
                                                        <h3 class="card-title">UCI</h3>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <img src="assets/images/NSE_IX-logo.png" routerLink="" class="logo" />
                        </div>
                        <!-- <div >
                        <a href=""><span   class="credits"><span><p style="padding-top: 2rem; font-size: medium;font-weight: 500;">Back To Dashboard</p></span></span></a>
                    </div> -->
                        

                        
                  
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-3">
                    <div class="row mt-xs-1 mb-xs-1 justify-content-end">
                      
                        <!-- <div class="col-lg-4 mt-2" *ngIf="collateralMenu?.isAccessAllowed == 'Y'">
                            <a class="contact" routerLink="/timing">Collateral Timing</a>
                        </div> -->
                        <!-- <div class="col-lg-4 mt-2">
                            <a class="contact" routerLink="/form/contact">Contact Us</a>
                        </div> -->
                        <!-- <div class="col-lg-4 mt-2 marketopen">Market Open</div> -->
                        <!-- <div class="col-lg-2 mt-2 dropdown" *ngIf="userDetails?.celUserType!=0">
                            <a href="" class="mb-0 dropdown-toggle notification" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-bell-o fa-2x" aria-hidden="true"></i>
                                <span class="badge badge-color"
                                    *ngIf="notifications?.length">{{notifications.length}}</span>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <div class="d-flex dropdown-item" *ngFor="let data of notifications; let i=index">
                                    <span *ngIf="i<5">User {{data.userId}} has crossed {{getAlertType(data.alertType)}}
                                        threshold
                                        {{data.bandPrecent}}% for {{getCelType(data.celType)}}</span>
                                </div>
                                <div class="mr-3 mb-0 view-more dropdown-item">
                                    <a class="view-more" routerLink="/cel/notification">View More</a>(Showing
                                    {{notifications.length>5?5:notifications.length}} of {{notifications.length}}
                                    records)
                                </div>
                            </div>
                        </div> -->
                        
                        <div class="col-lg-8">

                            
                            

                            <div class="d-flex justify-content-end">
                                <!-- <a href="" type="button" style="padding-top: 13px; padding-right: 50px"> 
                                      <svg xmlns="http://www.w3.org/2000/svg" height="1.7em" fill="#3b2c8f" viewBox="0 0 576 512">
                                        <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/></svg>
                                </a> -->
                                  
                                <div class="dropdown changepass">                                 
                                    <!-- <a href="" class="mb-0 dropdown-toggle" type="button" id="dropdownMenuButton"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class='fa fa-user' style='color: #3b2c8f'></i>
                                        <div class="ml-2">
                                            <div class="initial">
                                                {{userDetails?.loginId && userDetails?.loginId[0]}}
                                            </div>
                                        </div>
                                        <span class="caret"></span>
                                    </a> -->
                                  <i class="fa fa-bell bell" aria-hidden="true"></i>   
                                  <a href="" class="mb-0 dropdown-toggle " type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <!-- <i class='fa fa-user' style='color: #3b2c8f'></i> -->
                                    <div class="ml-2">
                                        <div class="fa fa-user userIcon ">
                                            <!-- {{userDetails?.loginId && userDetails?.loginId[0]}} -->
                                        </div>
                                    </div>
                                    <span class="caret"></span>
                                 </a>
                                 <p class="dateAndTime" id="date_time" >{{today | date:'MMM d, y h:mm:ss a' }}</p>                         

                                       

                                  

                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <div class="d-flex dropdown-item">
                                            <div class="nowrap mr-2">
                                                <div class="mb-0 nowrap userdropdown">
                                                    <p>{{userDetails?.loginId}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="dropdown-item pointer" (click)="profile()">Profile
                                        </a>
                                        <a class="dropdown-item pointer" (click)="changePassword()">Change Password
                                        </a>
                                        <a class="dropdown-item pointer" (click)="logout()">Logout
                                        </a>
                                    </div>

                                    
                                     <!-- <p class="dateAndTime" id="date_time" >{{today | date:'MMM d, y h:mm:ss a' }}</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="fullwrapper">
    <app-sidebar [ngClass]="fullscreen ? 'active' : ''"></app-sidebar>
    <div id="content" (click)="hideSidebar()">
        <router-outlet></router-outlet>
    </div>
  
</div>
