<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex usercontrol">
                <h1>Extranet - Common</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li><a>Extranet</a></li>
                    <li class="active"> <a routerLink="/extranet/common" aria-current="page">Common</a></li>
                </ul>
            </div>
        </div>
    </div>
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
        <p class="white">Please wait.</p>
    </ngx-spinner>
    <div class="card card-body colorborder mb-3 col-lg-12">
        <div class="row treeviewReload">
            <div class="pl-4 mb-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" (click)="getFiles()"
                    class="bi bi-arrow-repeat pointer reload" viewBox="0 0 16 16">
                    <title>Reload</title>
                    <path
                        d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path fill-rule="evenodd"
                        d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                </svg>
            </div>
        </div>
        <div class="row treeviewClear">
            <div class="pl-4 mb-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#777" (click)="clearSearch()"
                    class="bi bi-x-lg i-close" viewBox="0 0 16 16">
                    <path
                        d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                </svg>
            </div>
        </div>
        <ngx-treeview [items]="items" [itemTemplate]="itemTemplate1" [config]="treeConfig">
        </ngx-treeview>
    </div>
</div>
<ng-template #itemTemplate1 let-item="item" let-onCollapseExpand="onCollapseExpand"
    let-onCheckedChange="onCheckedChange">
    <div class="d-flex fileName">
        <div class="row-item">
            <a (click)="onCollapseExpand()">
                <svg *ngIf="item.value.type=='folder' && item.collapsed" xmlns="http://www.w3.org/2000/svg" width="25"
                    height="25" fill="#B8860B" class="bi bi-folder-fill folderIcon" viewBox="0 0 16 16">
                    <path
                        d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z" />
                </svg>
                <svg *ngIf="item.value.type=='folder' && !item.collapsed" xmlns="http://www.w3.org/2000/svg" width="25"
                    height="25" fill="#B8860B" class="bi bi-folder2-open folderIcon" viewBox="0 0 16 16">
                    <path
                        d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14V3.5zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5V6zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7H1.633z" />
                </svg>
                <svg *ngIf="item.value.type=='file' && item.text.split('.')[item.text.split('.').length-1].toUpperCase()=='PDF'"
                    xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#2c3592"
                    class="bi bi-file-earmark-pdf fileIcon" viewBox="0 0 16 16">
                    <path
                        d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                    <path
                        d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                </svg>
                <svg *ngIf="item.value.type=='file' && (item.text.split('.')[item.text.split('.').length-1].toUpperCase()=='XLS' || item.text.split('.')[item.text.split('.').length-1].toUpperCase()=='XLSX')"
                    xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#2c3592"
                    class="bi bi-file-earmark-excel fileIcon" viewBox="0 0 16 16">
                    <path
                        d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z" />
                    <path
                        d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                </svg>
                <svg *ngIf="item.value.type=='file' && (item.text.split('.')[item.text.split('.').length-1].toUpperCase()=='ZIP' || item.text.split('.')[item.text.split('.').length-1].toUpperCase()=='GZ')"
                    xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#2c3592"
                    class="bi bi-file-earmark-zip fileIcon" viewBox="0 0 16 16">
                    <path
                        d="M5 7.5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v.938l.4 1.599a1 1 0 0 1-.416 1.074l-.93.62a1 1 0 0 1-1.11 0l-.929-.62a1 1 0 0 1-.415-1.074L5 8.438V7.5zm2 0H6v.938a1 1 0 0 1-.03.243l-.4 1.598.93.62.929-.62-.4-1.598A1 1 0 0 1 7 8.438V7.5z" />
                    <path
                        d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1h-2v1h-1v1h1v1h-1v1h1v1H6V5H5V4h1V3H5V2h1V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                </svg>
                <svg *ngIf="item.value.type=='file' && !(item.text.split('.')[item.text.split('.').length-1].toUpperCase()=='PDF' || item.text.split('.')[item.text.split('.').length-1].toUpperCase()=='XLS' || item.text.split('.')[item.text.split('.').length-1].toUpperCase()=='XLSX' || item.text.split('.')[item.text.split('.').length-1].toUpperCase()=='ZIP' || item.text.split('.')[item.text.split('.').length-1].toUpperCase()=='GZ')"
                    xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#2c3592"
                    class="bi bi-file-earmark fileIcon" viewBox="0 0 16 16">
                    <path
                        d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                </svg>
                <span>
                    {{item.text}}
                </span>
            </a>
        </div>
        <div class="ml-auto fileInfo">
            <span class="col-lg-4">{{item.value.filesize}}</span>
            <span class="col-lg-4 fileDate">{{item.value.modifiedDate|date:'medium'}}</span>
            <span class="col-lg-2">
                <a *ngIf="item.value.type=='file'" (click)="downloadFile(item)" class="download pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#3A2E7E" class="bi bi-download"
                        viewBox="0 0 16 16">
                        <path
                            d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path
                            d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                </a>
                <span class="ml-3" *ngIf="item.value.type=='folder'"></span>
            </span>
        </div>
    </div>
    <hr class="divider">
</ng-template>