<header>
    <div class="container-fluid">
        <div class="d-flex">
            <div class="logo"><img src="assets/images/NSE_IX-logo.png"></div>
            <div class="positionRight d-flex">
                <!-- <div class="logo"><img src="assets/images/nselogo.gif"></div> -->
            </div>
        </div>

    </div>
</header>
<div class="col-lg-12">
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
        <p class="customParagraph">Please wait.</p>
    </ngx-spinner>
    <div class="row login-slider">
        <div class="col-lg-4 offset-lg-4">
            <div class="form-section colorborder">
                <div class="login-inner-form">
                    <p>{{message}} </p>
                    <div>
                        <button routerLink="../login" class="btn btn-primary m-3">Go to Login Page</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright">
        Best viewed with Chrome browser. Copyright © 2022 National Stock Exchange of India Ltd. All rights reserved
    </div>
</div>