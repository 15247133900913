export const config = {
    "URL": {
        "captchaURL": "/security/login",
        "validateURL": "/security/validateUser",
        "authenticateURL": "/security/authenticateUser",
        "logoutURL": "/security/logout-jwt-token",
        "generateOTPTokenURL": "/2FA/sendOtp",
        "validateOTPTokenURL": "/2FA/verifyOtp",
        "generateOTPURL": "/2FA/passwordUpdation/sendOtp",
        "validateOTPURL": "/2FA/passwordUpdation/verifyOtp",
        "forgotPasswordURL": "/security/forgotPassword",
        "unlockAccountURL": "/security/unlockUser",
        "changePasswordURL": "/security/changePassword",
        "userListURL": "/security/getUsers",
        "addUserURL": "/security/createUser",
        "updateUserURL": "/security/updateUser",
        "getUserDetails": "/security/getUserMenus",
        "getUserMenusByLoginId": "/security/getUserMenusByLoginId",
        "getEntityTypeData": "/security/getAutoPopulatedUserValues",
        "getEntityCodeData": "/security/getEntityCodeData",
        "getAllMenus": "/security/getAllMenus",
        "saveUserMenuMappings": "/security/saveUserMenuMappings",
        "menuList": "/security/getMenu",
        "saveMenu": "/security/saveMenu",
        "roleList": "/security/getRole",
        "saveRole": "/security/saveRole",
        "getRoleDataById": "/security/getRoleDataById",
        "getFileDownloadExt": "/extranet/getFileDownloadable",
        "getExtranetFiles": "/extranet/getMemberDirStructure",
        "uploadExtranetFile": "/extranet/uploadExtranetFile",
        "getFolderList": "/extranet/getFolderList",
         "verifyEmail": "/security/verifyEmail",
        "sendVerificationLink": "/security/sendVerificationLink",
        "revampTradeApplication": "/enit/app/security/ssoCreate",
        "uciRevampApplication": "/uci/app/security/ssoCreate",
        "revampTradeApplicationInspection": "/insp/app/security/ssoCreate",
        "revampMembership": "/membershipdb/app/security-mem/ssoCreate",
        "saveDetails": "/security/saveDetails",
        "uploadFile": "/security/uploadFile",
        "getExchangeAndRegulators": "/security/get-country-details",
        "getParameterFromType": "/security/getParameterFromType",

        "sendOtpOnForRbdEmail": "/security/sendOtpOnForRbdEmail",
        "verfiyRbdOtp": "/security/verfiyRbdOtp",
        "celaringMeberDetails": "/security/celaringMeberDetails",
        "getCountryDetails": "/security/getCountries",

        // "getAllLesOprnlSchemeData": "/les/lesoperatinal/getAllLesOprnlSchemeData",
        // "getClientsAndSchemeList": "/les/lesoperatinal/getClientsAndSchemeList",
        "getDailyFileRecord": "/les/lesoperatinal/getDailyFileRecord",
        "getHourlyFileRecords": "/les/lesoperatinal/getHourlyFileRecords",
        "downloadHourlyIncentiveFile": "/les/lesoperatinal/downloadHourlyIncentiveFile",
        "downloadDailyIncentiveFile": "/les/lesoperatinal/downloadDailyIncentiveFile",
        "filterHistoricalData": "/les/lesoperatinal/filterHistoricalData",
        "getClientsAndSchemeList": "/les/LesSearchDropdownValues",
        "getListMemberFiles":"/les/listMemberFiles",
        "downloadHourlyAndDailyFile":"/les/download",
        "getAllLesOprnlSchemeData": "/les/getAllHourlyMM",

    },

    "data": {
        "passwordPattern": "((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@_]).{12,})",
        "userPattern": "^[a-zA-Z0-9@_]*$",
        "captchaPattern": "^[a-zA-Z0-9]*$",
        "passwordPolicy": "Password should contain atleast 1 lower case character, 1 upper case character, 1 numeric and 1 special character. Password Length should be minimum 12 characters. ",
        "entityTypeData": [{
            "id": "TM",
            "name": "Trading Member",
            "menu": ["extranet", "common", "member", "eEnit", "enit", "enitAudit", "eEnitTradeRevamp", "rInspection","uciApplication", "les", "lesOperation",],
        }, {
            "id": "ADMIN",
            "name": "Admin",
            "menu": ["admin", "userlist", "useraccess", "eEnit", "enit", "enitAudit", "eEnitTradeRevamp", "rInspection", "membership", "les", "lesOperation","uciApplication"]
        }, {
            "id": "AUDITOR",
            "name": "AUDITOR",
            "menu": ["enit"]
        }],
        "userTypeData": [{
            "id": 1,
            "name": "Super Admin",
            "menu": ["admin", "userlist", "useraccess"]
        }, {
            "id": 2,
            "name": "Admin",
            "menu": []
        }, {
            "id": 3,
            "name": "Auditor",
            "menu": []
        }],
        "celUserTypeData": [{
            "id": 0,
            "name": "NA",
            "menu": []
        }, {
            "id": 1,
            "name": "Super Admin",
            "menu": ["cel", "assignBranchAdmin", "monitoringEnable", "limitSetup", "alertSetup", "celMonitoring", "prodCoefSetup", "admin", "celUserMgmt", "activateCelUser", "celAuditUser"]
        }, {
            "id": 2,
            "name": "Admin",
            "menu": ["cel", "monitoringEnable", "limitSetup", "alertSetup", "celMonitoring", "prodCoefSetup", "activateCelUser", "celAuditUser"]
        }],
        "countryCodeData": [{
            "id": 91,
            "name": "India"
        }, {
            "id": 65,
            "name": "Singapore"
        }, {
            "id": 852,
            "name": "Hong Kong"
        }]
    },
    "successResponse": {
        "login": {
            "LOGIN_SUCCESS": "User logged in successfully",
            "OTP_SENT_TO_USER": "One time password (OTP) has been sent to registered mobile number & email address",
            "OTP_VALIDATE_SUCCESS": "OTP validated successfully",
            "PASSWORD_UPDATE_SUCCESS": "Your password is updated successfully",
            "UNLOCK_SUCCESS": "Your account is unlocked successfully"
        },
        "user": {
            "ADD_USER_SUCCESS": "User added successfully",
            "UPDATE_USER_SUCCESS": "User details have been updated",
            "DELETE_USER_SUCCESS": "User deleted successfully",
            "ADD_MENU_SUCCESS": "Menu added successfully",
            "UPDATE_MENU_SUCCESS": "Menu details have been updated",
            "USER_ACCESS_SUCCESS": "User access details have been updated"
        }

    },
    "failureResponse": {
        "login": {
            "invalidCred": "Invalid credentials",
            "invalidCaptcha": "Invalid captcha. Please type the text displayed in image",
            "USER_DISABLED_MESSAGE": "Your Login ID is currently locked. Please click on \"Unlock Account\" link or contact System Administrator to unlock user",
            "IS_FIRST_TIME_LOGIN_MSG": "You have logged for the first time. Please change the password.",
            "USER_PASSWORD_EXPIRED": "Your password is expired. Please change the password.",
            "DEFAULT_PASSWORD": "You have logged in with the default password. Please change the password.",
            "PASSWORD_PRESENT_IN_HISTORY": "New password should not be same as last 5 passwords",
            "LOGGED_IN_OTHER_SYSTEM": "You have already logged in from other system.",
            "UNAUTHORIZED_USER": "You are not authorized to perform this operation",
            "OTP_FAILED": "Not able to send OTP. Please try again later",
            "OTP_DISABLED": "OTP is disabled for this user.",
            "INVALID_OTP": "Invalid OTP",
            "SERVICE_UNAVAILABLE": "Service is not available. Please try again later.",
            "OTP_DISABLED_TEMPORARYLY": "OTP is temporarily disabled. Please try again after some time."
        },
        "user": {
            "ADD_USER_FAIL": "Not able to add user",
            "UPDATE_USER_FAIL": "Not able to update user",
            "DELETE_USER_FAIL": "Not able to delete user",
            "ADD_MENU_FAIL": "Not able to add menu",
            "UPDATE_MENU_FAIL": "Not able to update menu",
            "USER_ACCESS_FAIL": "Not able to save user access details"
        },
        "SOMETHING_WENT_WRONG": "Something went wrong"
    }
};