import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ComponentComponent } from './component/component.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { LoginBaseComponent } from './login/login-base/login-base.component';
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { ShortformComponent } from './component/shortform/shortform.component';
import { ProfileComponent } from './login/profile/profile.component';
import { AuthGuard } from './service/auth.guard';
import { MenuGuard } from './service/menu.guard';
import { AccessGuard } from './service/access.guard';
import { SubmenuGuard } from './service/submenu.guard';
import { ContactComponent } from './login/contact/contact.component';
import { UsercontrolComponent } from './component/usercontrol/usercontrol.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { RemoteBrokerDealerComponent } from './remote-broker-dealer/remote-broker-dealer.component';


const routes: Routes = [
  {
    path: 'login', component: LoginBaseComponent,
    loadChildren: () => import('./login/login.module')
      .then(m => m.LoginModule)
  }, {
    path: 'verifyEmail', component: VerifyEmailComponent
  },
  {
    path: 'rtpRegistration', component: RemoteBrokerDealerComponent,
  },
  {
    path: '', component: ComponentComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: DashboardComponent },
      {
        path: 'form', component: ShortformComponent,
        children: [{ path: 'changepassword', component: ChangePasswordComponent },
        { path: 'profile', component: ProfileComponent },
        { path: 'contact', component: ContactComponent }
        ]
      },
      {
        path: 'admin', canActivate: [MenuGuard, AccessGuard],
        children: [{ path: 'userlist', canDeactivate: [SubmenuGuard], loadChildren: () => import('./component/usermanagement/usermanagement.module').then(m => m.UsermanagementModule) },
        { path: 'useraccess', canDeactivate: [SubmenuGuard], loadChildren: () => import('./component/accessmanagement/accessmanagement.module').then(m => m.AccessmanagementModule) }]
      },
      { path: 'menu', loadChildren: () => import('./component/menumanagement/menumanagement.module').then(m => m.MenumanagementModule) },
      { path: 'role', loadChildren: () => import('./component/rolemanagement/rolemanagement.module').then(m => m.RolemanagementModule) },
      { path: 'extranet', canActivate: [MenuGuard, AccessGuard], loadChildren: () => import('./component/extranet/extranet.module').then(m => m.ExtranetModule) },
      {
        path: 'usercontrol', canActivate: [AccessGuard], component: UsercontrolComponent,
        children: [{
          path: 'admin', canActivate: [MenuGuard, AccessGuard],
          children: [{ path: 'userlist', canDeactivate: [SubmenuGuard], loadChildren: () => import('./component/usermanagement/usermanagement.module').then(m => m.UsermanagementModule) },
          { path: 'useraccess', canDeactivate: [SubmenuGuard], loadChildren: () => import('./component/accessmanagement/accessmanagement.module').then(m => m.AccessmanagementModule) },]
        },
        { path: 'extranet', canActivate: [MenuGuard], loadChildren: () => import('./component/extranet/extranet.module').then(m => m.ExtranetModule) }
        ]
      },
      { path: 'les',  loadChildren: () => import('./component/les/les.module').then(m => m.LesModule) }
      
 
    ]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [AuthGuard, MenuGuard, SubmenuGuard, AccessGuard]
})
export class AppRoutingModule {

}
