import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ExtranetRoutingModule } from './extranet-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { CommonComponent } from './common/common.component';
import { CircularComponent } from './circular/circular.component';
import { MemberfolderComponent } from './memberfolder/memberfolder.component';
// import { TreeviewModule } from 'ngx-treeview';
import { TreeviewModule } from 'ngx-treeview2';



@NgModule({
  declarations: [CommonComponent, CircularComponent, MemberfolderComponent],
  imports: [
    CommonModule,
    ExtranetRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgxSpinnerModule,
    TreeviewModule.forRoot(),
  ],

})
export class ExtranetModule { }