import { NgModule, Component,  CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BaseChartDirective } from 'ng2-charts';
import { DataTablesModule } from 'angular-datatables';
import { ComponentComponent } from './component/component.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoginModule } from './login/login.module';
import { UsermanagementModule } from './component/usermanagement/usermanagement.module';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { allIcons, NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';
import { MenumanagementModule } from './component/menumanagement/menumanagement.module';
import { RolemanagementModule } from './component/rolemanagement/rolemanagement.module';
// import { ExtranetModule } from './component/extranet/extranet.module';
import { AccessmanagementModule } from './component/accessmanagement/accessmanagement.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConfigService } from './service/config.service';
import { LoginService } from './service/login.service';
import { UtilityService } from './service/utility.service';
import { SharedModule } from 'src/app/shared/shared.module'
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShortformComponent } from './component/shortform/shortform.component';
import { AppService } from './service/app.service';
import { DatePipe } from '@angular/common';
import { BnNgIdleService } from 'bn-ng-idle';
import { NgxSpinnerModule } from 'ngx-spinner';
// import { EnitModule } from './component/enit/enit.module';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import {LoggingInterceptor} from './interceptor/logging.interceptor';
import { RouterModule } from '@angular/router';
//import { AgGridModule } from 'ag-grid-angular';
import { UsercontrolComponent } from './component/usercontrol/usercontrol.component';
// import { TreeviewModule } from 'ngx-treeview';
import { TreeviewModule } from 'ngx-treeview2';
import { ExtranetModule } from './component/extranet/extranet.module';
// import { CelModule} from './component/cel/cel.module';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { ApplicationPopoverComponent } from './component/application-popover/application-popover.component';
import { MemberportalSsoService } from './service/memberportal-sso.service';
import { RemoteBrokerDealerComponent } from './remote-broker-dealer/remote-broker-dealer.component';
import { RbdServiceService } from './service/rbd-service.service';
import { LesModule } from './component/les/les.module';
@NgModule({
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports:[
    NgxSpinnerModule,
  ]
  ,
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    ComponentComponent,
    DashboardComponent,
    ShortformComponent,
    UsercontrolComponent,
    VerifyEmailComponent,
    ApplicationPopoverComponent,
    RemoteBrokerDealerComponent,
    // AuditorRegistrationComponent,
    // AuditorRegistrationReportComponent,
    // AuditApproveRejectComponent,
    // ChangeClearingMemberFormComponent,
    // ChangeClearingMemberReportComponent,
    // ChangeClearingMemberFormViewComponent,
    // UciClientAdditionComponent,
    // UciClientReportsComponent,
    // UciViewUsersComponent,
    // UciCountryDetailComponent,
    // UciFileuploadMisComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    //  ChartsModule,
    BaseChartDirective,
    DataTablesModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    LoginModule,
    HttpClientModule,
    UsermanagementModule,
    MenumanagementModule,
    RolemanagementModule,
    AccessmanagementModule,
    //EnitModule,
   // AgGridModule,
    BsDatepickerModule.forRoot(),
    NgxBootstrapIconsModule.pick(allIcons),
    SharedModule,
    NgxSpinnerModule,
    RouterModule,
    ExtranetModule,
    LesModule,
    // CelModule,
    TimepickerModule.forRoot(),
    TreeviewModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      extendedTimeOut: 3000,
      positionClass: 'toast-top-right',
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,
    })
  ],
  providers: [
    BsDatepickerConfig,
    AppService,
    ConfigService,
    LoginService,
    UtilityService,
    DatePipe,
    BnNgIdleService,
    MemberportalSsoService,
    RbdServiceService,
    {provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
