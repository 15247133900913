import { ApplicationRef, ChangeDetectorRef, Inject, Injectable,ViewRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import DataTables from 'datatables.net';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class DatatableService {
  
  constructor(
    private appRef: ApplicationRef,
  ) { }

  //for dynamic, parameter based initialization of datatables
  setDatatable(tableId: any, columnCount: any, pageLength: any, lengthArray: any) {

    let columnArray: any = Array.from(Array(columnCount ? columnCount : 0).keys());
    tableId = '#' + tableId;
    setTimeout(() => {
      $(tableId).DataTable({
        processing: true,
        orderCellsTop: true,
        pageLength: pageLength,
        dom: "Blrtip",
        destroy: true,
        lengthMenu: lengthArray,
        pagingType: 'simple_numbers',
        language: {
          paginate: {
            previous: 'Previous',
            next: 'Next'
          }
        },
          buttons:
          [{
            extend: 'copy',
            titleAttr: 'Copy',
            exportOptions: {
              columns: columnArray,
            }
          }, {
            extend: 'excel',
            titleAttr: 'Export to excel',
            exportOptions: {
              columns: columnArray,
            }
          }, {
            extend: 'pdf',
            orientation: 'landscape',
            pageSize: 'A4',
            titleAttr: 'Download as PDF',
            exportOptions: {
              columns: columnArray,
            }
          }],
        initComplete: function () {
          this.api().columns().every(function () {
            $(tableId + ' .column-search input').val('');
            $(tableId + ' .column-search select').val('.*');
            $(tableId + ' .column-search').on('keyup change', 'input', function () {
              $(tableId).DataTable()
                .column($(this).parent().parent().index())
                .search(this.value)
                .draw();
            });
            $(tableId + ' .column-search').on('keyup change', 'select', function () {
              $(tableId).DataTable()
                .column($(this).parent().parent().index())
                .search("^" + this.value + "$", true, false, false)
                .draw();
            });
          })
        }
      });
      this.appRef.tick();
    }, 1);
  }

  setDatatableWithoutButtons(tableId: any, pageLength: any){  
    tableId = '#' + tableId;
    setTimeout(() => {
      $(tableId).DataTable({
        dom: "rti",
        orderCellsTop: true,
        responsive: true,
        destroy: true,
        processing: true,
        pageLength: pageLength,
      });
      this.appRef.tick();
    }, 1);
  }
}
