<header>
    <div class="container-fluid">
        <div class="d-flex">
            <div class="logo"><img src="assets/images/NSE_IX-logo.png"> </div>
            <div class="positionRight d-flex">
                <div class="d-flex justify-content-end">

                </div>

            </div>
        </div>

    </div>
    <div class="seperator">
        <div class="col-lg-1 seperator1"></div>
        <div class="col-lg-2 seperator2"></div>
        <div class="col-lg-4 seperator3"></div>
        <div class="col-lg-5 seperator4"> </div>
    </div>
</header>
<div class="container-fluid mt-2 mb-2 theme">
    <div>

        <!-- <div class="card card-body" style="border-radius: 1rem;">
        
        <div class="row">
            <div class="col-md-5 logo"></div>
        <img  class="img-fluid" src="assets/images/rbd.png">
    </div></div>  -->
        <script>
            $("input:checkbox").on('click', function () {
                var $box = $(this);
                if ($box.is(":checked")) {
                    var group = "input:checkbox[name='" + $box.attr("pspcm") + "']";
                    $(group).prop("checked", false);
                    $box.prop("checked", true);
                } else {
                    $box.prop("checked", false);
                }
            });
        </script>

        <form [formGroup]='rbdRegistrationForm'>
            <div class="card card-body rbdForm">
                <div class="row justify-content-center">
                    <h2 class="rbdh2">Expression of Interest for Remote Trading Participant</h2>

                </div>
                <!-- <div class="card" style="background: #392e7d">
        
                     <div class="row">
                         <div class="col-md-5"></div>
                        <h2>Remote Broker Dealer</h2> 
                    
                </div>  

             
                    
            </div>-->
                <!-- <div  class="seperator">
                <div  class="col-lg-1 seperator1"></div>
                 <div  class="col-lg-2 seperator2"></div>
                    <div  class="col-lg-4 seperator3"></div>
                 <div  class="col-lg-5 seperator4"> </div>
            </div> -->
                <div class="card card-body bg mt-1">
                    <!-- <h3>Borker Details</h3> -->
                    <div class="row">
                        <div class="col-lg-4">
                            <label>Entity Name <span class="red">*</span> </label>
                            <input type="text" value="" class="form-control" formControlName="entityName"
                                placeholder="Enter entity Name">

                            <div *ngIf="f.entityName.invalid && f.entityName.touched">
                                <span *ngIf="f.entityName.errors.required" class="error nocase">
                                    Please Enter Entity name.
                                </span>
                                <span *ngIf="f.entityName.errors.pattern" class="error nocase">
                                    Entity name Should be alphanumeric character
                                </span>
                            </div>


                        </div>





                        <div class="col-lg-4">
                            <label>Legal Entity Identifier</label>
                            <input type="text" value="" class="form-control" formControlName="legalIdentifier"
                                placeholder="Enter Legal Entity Identifier">
                            <!-- <div *ngIf="f.legalIdentifier.invalid && f.legalIdentifier.touched">
                                <span *ngIf="f.legalIdentifier.errors.required" class="error nocase">
                                    Please enter legalIdentifier
                                </span>
                                <span *ngIf="f.legalIdentifier.errors.pattern" class="error nocase">
                                    legalIdentifier should be in alphanumeric character only.
                                </span>
                            </div> -->
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-lg-4">
                            <!-- <label>Country of the applicant <span class="red">*</span> </label>
                    <input type="text" value="" class="form-control" formControlName="country" placeholder="Enter the country name"> -->
                            <!-- <label>Country of the applicant<span class="red">*</span> </label>
                        <select class="form-control" formControlName="exchange" data-live-search="true"
                            title="Select Country of the applicant" data-style="btn-info" >                 
                            <option>dummy</option>
                        </select> -->


                            <label>Country of the applicant <span class="red">*</span> </label>
                            <select class="form-control" formControlName="country" data-live-search="true"
                                title="Select Country of the applicant" data-style="btn-info"
                                (change)="filterExchanges(); filterRegulators()">
                                <option *ngFor="let data of listofCountryDetails" value="{{data.id}}">
                                    {{data.countryName}}</option>
                            </select>

                            <div *ngIf="f.country.invalid && f.country.touched">
                                <span *ngIf="f.country.errors.required" class="error nocase">
                                    Please enter country
                                </span>
                                <span *ngIf="f.country.errors.pattern" class="error nocase">
                                    Country should be in character only.
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <label>Member Of The Exchange <span class="red">*</span> </label>
                            <select class="form-control" formControlName="exchange" data-live-search="true"
                                title="Select Member Of The Exchange" data-style="btn-info"
                                (change)="otherSelection(); filterRegulators()">
                                <option value="-1">Please select exchange</option>
                                <option *ngFor="let data of exchanges" value="{{data.exchangeId}}">
                                    {{data.exchangeName}}</option>
                            </select>

                            <div *ngIf="f.exchange.invalid && f.exchange.touched">
                                <span *ngIf="f.exchange.errors" class="error nocase">
                                    Please select exchange
                                </span>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <label>Registered with Regulator :
                                ( Name Of Regulator )</label>
                            <select class="form-control" formControlName="regulator" data-live-search="true"
                                title="Select Member Of The Exchange" data-style="btn-info" (change)="otherSelection()">
                                <option value="-1">Please select regulator</option>
                                <option *ngFor="let data of regulators" value="{{data.regulatorId}}">
                                    {{data.regulatorName}}</option>
                            </select>

                            <div *ngIf="f.regulator.invalid && f.regulator.touched">
                                <span *ngIf="f.regulator.errors" class="error nocase">
                                    Please select regulator
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="seperator">
                    <div class="col-lg-1 seperator1"></div>
                    <div class="col-lg-2 seperator2"></div>
                    <div class="col-lg-4 seperator3"></div>
                    <div class="col-lg-5 seperator4"> </div>
                </div>
                <!-- <hr> -->
                <div class="card card-body bg mt-1">
                    <!-- <h3>Contact Details</h3> -->
                    <div class="row">
                        <div class="col-lg-3">
                            <label>Contact Person Name<span class="red"> *</span> </label>
                            <input type="text" value="" class="form-control" formControlName="rbdcpn"
                                placeholder="Enter Contact Person Name">
                            <div *ngIf="f.rbdcpn.invalid && f.rbdcpn.touched">
                                <span *ngIf="f.rbdcpn.errors.required" class="error nocase">
                                    Please enter Contact Person Name
                                </span>
                                <span *ngIf="f.rbdcpn.errors.pattern" class="error nocase">
                                    Contact Person Name should be in character only.
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <label>Contact Number (ISD code) <span class="red"> *</span> </label>
                            <input type="text" value="" class="form-control" formControlName="cnumber"
                                placeholder="Enter Contact Number">
                            <div *ngIf="f.cnumber.invalid && f.cnumber.touched">
                                <span *ngIf="f.cnumber.errors.required" class="error nocase">
                                    Please enter Contact Number
                                </span>
                                <span *ngIf="f.cnumber.errors.pattern" class="error nocase">
                                    Contact Number Person Name should be in Number only.
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <label>Designation <span class="red">*</span> </label>
                            <input type="text" value="" class="form-control" formControlName="desg"
                                placeholder="Enter Designation">
                            <div *ngIf="f.desg.invalid && f.desg.touched">
                                <span *ngIf="f.desg.errors.required" class="error nocase">
                                    designation enter Contact Person Name
                                </span>
                                <span *ngIf="f.desg.errors.pattern" class="error nocase">
                                    designation Person Name should be in character only.
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <label>E-mail <span class="red">*</span> </label>
                            <input type="text" value="" class="form-control" formControlName="email"
                                placeholder="Enter Email Id">
                            <div *ngIf="f.email.invalid && f.email.touched">
                                <span *ngIf="f.email.errors.required" class="error nocase">
                                    Please enter Email id.
                                </span>
                                <span *ngIf="f.email.errors.pattern" class="error nocase">
                                    email id is not valid.
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="seperator">
                    <div class="col-lg-1 seperator1"></div>
                    <div class="col-lg-2 seperator2"></div>
                    <div class="col-lg-4 seperator3"></div>
                    <div class="col-lg-5 seperator4"> </div>
                </div>
                <div class="card card-body bg mt-1">
                    <label>Is your current membership with the above selected exchange for more than 1 year<span
                            class="red">*</span> </label>

                    <div class="col-lg-1">
                        <input type="radio" class="form-check-input" formControlName="memberShip" value="Y"
                            (click)="hideShow()"><label>yes</label>
                    </div>

                    <!-- <div class="col-lg-1">
                        <input type="file" id="uploadFile" class="form-control" formControlName="file" />
                    </div> -->
                    <div *ngIf="f.memberShip.value == 'Y'" class="col-lg-4 form-group">
                        <input id="upload_file" class="form-control" formControlName="file" type="file"
                            accept=".pdf, image/*" (change)="onChange($event)" />
                        <!-- <p *ngIf="f.file.invalid && f.file.touched" class="error">
                            Please upload file
                        </p> -->
                        <div *ngIf="fileError">
                            <p class="error">File should be in pdf or image format</p>
                        </div>
                    </div>

                    <div class="col-lg-1">
                        <input type="radio" class="form-check-input" formControlName="memberShip" value="N"
                            (click)="hideShow()"><label>No</label>
                    </div>
                    <div *ngIf="f.memberShip.value == 'N'">
                        <p class="error">Basic criteria not fulfilled, For details you can contact on
                            bd&#64;nseix.com</p>
                    </div>

                </div>
                <div class="seperator">
                    <div class="col-lg-1 seperator1"></div>
                    <div class="col-lg-2 seperator2"></div>
                    <div class="col-lg-4 seperator3"></div>
                    <div class="col-lg-5 seperator4"> </div>
                </div>
                <div class="card card-body bg" id="otp">
                    <div class="row">
                        <div class="col-lg-2">
                            <label> <i class="fa fa-envelope" aria-hidden="true"></i> &nbsp; Email Verification
                                Code<span class="red">*</span></label>
                            <input type="text" value="" class="form-control" formControlName="otp"
                                placeholder="Enter Verification Code">
                        </div>
                        <div class="col-lg-4">
                            <label>Remarks</label>
                            <textarea class="mt-2 form-control" contenteditable formControlName="remarks"
                                            id="remarks" rows="5"></textarea>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2" *ngIf="isDisplaySendOtp">
                            <button class="btn btn-primary" (click)="sendOtpOnForRbdEmail()">Send Verification Code
                            </button>
                        </div>

                        <div class="col-lg-1" *ngIf="isDisplayVerify">
                            <button class="btn btn-primary  verficationCode" (click)="verfiyRbdOtp()">Verify
                            </button>
                        </div>
                        &nbsp;
                        <div class="col-lg-2" *ngIf="isDisplayVerify">
                            <button class="btn btn-primary" type="button" [disabled]="resendDisable"
                                (click)="sendOtpOnForRbdEmail()">{{resendDisable?(timeVal| minuteSeconds):''}} Resend
                            </button>
                        </div>
                     </div>



                    <div class="row">
                        <div class="col-lg-12">
                            <button class="btn btn-primary prefered button1 mt-2" data-toggle="modal"
                                data-target=".bd-example-modal-lg">
                                <p>Select Your Preferred Clearing Member:</p>
                            </button>
                            <input type="hidden" formControlName="clearingMember">
                            <div *ngIf="selectedClearingMember!=null">
                                <input type="text" class="form-control" readonly value="{{selectedClearingMember}}">
                            </div>
                        </div>

                        <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="card card-body">
                                    <div class="modal-content">

                                        <div class="modal-header orangeBg">
                                            <label>select one preferred Clearing Member:<span class="red"></span>
                                            </label>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span class="rbdh2"
                                                    aria-hidden="true"><button type="button"
                                                        class="btn btn-primary verficationCode"><strong class="black">
                                                            Close</strong></button></span>
                                            </button>

                                        </div>
                                        <div class="table-responsive">
                                            <table class="table common-table" datatable [dtOptions]="dtOptions"
                                                [dtTrigger]="dtTrigger" id="userList">
                                                <thead>
                                                    <tr>
                                                        <!-- <th scope="col">Select Clearing Member </th> -->
                                                        <th scope="col">Select only one Clearing member</th>
                                                        <th scope="col">Member Code</th>
                                                        <th scope="col">Member Name</th>
                                                        <th scope="col">type</th>
                                                    </tr>
                                                    <tr class="column-search">
                                                        <th><input type="text" class="form-control form-control-sm" />
                                                        </th>
                                                        <th><input type="text" class="form-control form-control-sm" />
                                                        </th>
                                                        <th><input type="text" class="form-control form-control-sm" />
                                                        </th>
                                                        <th></th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr *ngFor="let data of listofClearingMember">
                                                        <td><input type="radio" class="form-control-sm"
                                                                value="{{data.name}}" name="pspcm" id="pspcm"
                                                                (click)="onclickOnRadio(data)" /></td>
                                                        <td>{{data.memCode}} </td>
                                                        <td>{{data.name}}</td>
                                                        <td>{{data.type}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
                <div class="seperator">
                    <div class="col-lg-1 seperator1"></div>
                    <div class="col-lg-2 seperator2"></div>
                    <div class="col-lg-4 seperator3"></div>
                    <div class="col-lg-5 seperator4"> </div>
                </div>
                <div class="card card-body bg mt-1">
                    <div class="row">
                        <div class="col-lg-4"></div>
                        <div class="col-lg-2">
                            <button class="btn btn-primary form-control"
                                [disabled]="fileError || rbdRegistrationForm.invalid"
                                (click)="saveDetails()">Submit</button>
                        </div>&nbsp;
                        <div class="col-lg-2">
                            <button class="btn-primary  form-control verficationCode " (click)="reset()">Reset
                            </button>
                        </div>
                    </div>
                </div>


            </div>
        </form>
    </div>
</div>


<div class="modal fade p-2" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="errorModallabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="card-body modal-body">
                <div class="row">
                    <div class="col-lg-12">
                        <h6>Basic criteria not fulfilled, For details you can contact on bd&#64;nseix.com</h6>
                    </div>
                </div>
            </div>
            <div class="btn-group d-flex justify-content-center p-2" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-primary col-2" data-dismiss="modal" aria-label="Close"> OK
                </button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="successModal" tabindex="-1" role="dialog" aria-labelledby="successModallabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <button type="button" class="close close-position" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="row d-flex justify-content-center">
                    <div class="d-flex col-lg-12 justify-content-center">
                        <img src="assets/images/New_images/thank-you.png" alt="Avatar" class="image">
                    </div>
                </div>
            </div>
            <div class="btn-group d-flex justify-content-center p-2" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-primary col-2" data-dismiss="modal" aria-label="Close"> OK
                </button>
            </div>
        </div>
    </div>
</div>