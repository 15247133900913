<div class="container-fluid mt-3 mb-3 theme">
    <div class="row nowrap mt-4 mb-3" aria-label="breadcrumb">
        <div class="col-lg-10">
            <div class="d-flex usercontrol">
                <h1>Access Management</h1>
                <ul class="breadcrumbcustom" role="navigation" aria-labelledby="breadcrumb">
                    <li><a routerLink="">Home</a></li>
                    <li class="active"> <a routerLink="/admin/useraccess" aria-current="page">Access Management</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
        <p class="white">Please wait.</p>  
    </ngx-spinner>
    <div class="card card-body colorborder" *ngIf="showDataTable">
        <div class="row clearSearch">
            <button type="button" class="btn btn-cancel btn-sm" (click)="clearSearch()" aria-label="close">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x"
                    viewBox="0 0 15 15">
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg> Filter</button>
        </div>
        <div class="col-lg-6">
            <div class="row newReload">
                <div class="pl-4 mb-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                        class="bi bi-arrow-repeat pointer reload" viewBox="0 0 16 16" (click)="getUserList()">
                        <title>Reload</title>
                        <path
                            d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                        <path fill-rule="evenodd"
                            d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                    </svg>
                </div>
            </div>
        </div>
        <table id="userAccessList" class="table common-table nowrap" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
                <tr>
                    <th class="pre-wrap">No</th>
                    <th class="pre-wrap">Login id</th>
                    <th class="pre-wrap">User Name</th>
                    <th class="pre-wrap">Entity Code</th>
                    <!-- <th class="pre-wrap">Entity Type</th> -->
                    <th class="pre-wrap">User Type</th>
                    <th class="pre-wrap">Status</th>
                    <th class="pre-wrap">edit</th>
                </tr>
                <tr class="column-search">
                    <th></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <th><input type="text" class="form-control form-control-sm" /></th>
                    <!-- <th><input type="text" class="form-control form-control-sm" /></th> -->
                    <th>
                        <select class="form-control form-control-sm">
                            <option value=".*" selected>Select Status</option>
                            <option value=".*Active.*">Active</option>
                            <option value=".*Deactive.*">Deactive</option>
                        </select>
                    </th>
                    <th></th>
                  </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of users">
                    <td>{{users.indexOf(user)+1}}</td>
                    <td class="nocase">{{user.userLoginId}}</td>
                    <td>{{user.userName}}</td>
                    <td>{{user.entityCode}}</td>
                    <!-- <td>{{user.entityType}}</td> -->
                    <td>{{user.userType=='1'?'Super Admin':(user.userType=='2'?'Admin':'Auditor')}}</td>
                    <td>
                        <span [ngClass]="{'green':user.isActive=='Y','red':user.isActive=='N'}" class="mfont">{{user.isActive=='Y'?'Active':'Deactive'}}</span>
                    </td>
                    <td><i class="fa fa-pencil pointer usercontrol" aria-hidden="true" (click)="editUserData(user.userId)"></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>