import { Component, OnDestroy, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { TreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { TreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview2';
import { ExtranetService } from 'src/app/service/extranet.service';
import { ToastrService } from 'ngx-toastr';
import { throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { config } from 'src/app/valueObject/config';
import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';
import { Title } from '@angular/platform-browser';
import { UserDetailsService } from 'src/app/service/user-details.service';
import { UserControlService } from 'src/app/service/user-control.service';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
@Component({
  selector: 'app-memberfolder',
  templateUrl: './memberfolder.component.html',
  styleUrls: ['./memberfolder.component.css']
})
export class MemberfolderComponent implements OnInit, AfterViewInit {
  config: any = config;
  fileUpload: FormGroup;
  fileName: string;
  folderListData: any = [];
  file: File;
  user: any = {};
  access: any = {};
  dtOptions: any;
  menuCode: string = "member";
  userControlId: string = '';
  dtTrigger: Subject<any> = new Subject<any>();
  colatFormat: string;
  limitFormat: string;
  upldFiles: any = [];
  dnldFiles: any = [];
  custom1: string;
  custom2: string;
  items: any = [];
  path: string;
  today: Date = new Date();
  treeConfig: any = TreeviewConfig.create({
    hasCollapseExpand: true,
    hasFilter: true,
    maxHeight: 550
  });
  @ViewChild('selectpicker') selectPicker: ElementRef;
  @ViewChild(TreeviewComponent, { static: false })
  treeviewComponent: TreeviewComponent;

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    private titleService: Title,
    private userDetailsService: UserDetailsService,
    private spinner: NgxSpinnerService,
    private userControlService: UserControlService,
    private extranetService: ExtranetService,
    private cdref: ChangeDetectorRef,
  ) { }

  get f() { return this.fileUpload.controls; }

  ngOnInit(): void {
    this.userControlId = this.userControlService.getUserControlId();
    this.user = this.userControlId ? this.userControlService.getUserControlDetails() : this.userDetailsService.getUserDetails() || {};
    this.access = this.userControlId ? this.userControlService.checkUserAccess(this.menuCode) : this.userDetailsService.checkUserAccess(this.menuCode) || {};
    this.getFiles();
    this.titleService.setTitle('Extranet - Member')
    this.colatFormat = "G_T_MRG_TM_[DDMMYYYY]_nn.csv OR G_T_MRG_CM_[DDMMYYYY]_nn.csv";
    this.limitFormat = "[DDMMYYYY].csv";
    this.fileUpload = this.fb.group({
      fileType: ['', Validators.required],
      file: ['', Validators.required]
    })
    this.dtOptions = {
      processing: true,
      orderCellsTop: true,
      dom: "lrtip",
      pageLength: 5,
      lengthMenu: [5, 10, 15, 20],
    }
    this.getFolderList();
  }

  ngAfterViewInit(): void {
    $('.selectpicker').selectpicker();
  }

  removeFile() {
    this.f.file.setValue('');
    this.fileName = '';
  }

  onChange(file) {
    this.file = file.files[0];
    this.fileName = file.files[0].name;
  }

  changeOption() {
    this.f.file.setValue('');
    this.fileName = '';
  }

  getFolderList() {
    this.spinner.show();
    this.extranetService.getExtranetFolderList(this.userControlId).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.items = [];
        this.cdref.detectChanges();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      this.spinner.hide();
      if (response.statusCode == 200) {
        this.folderListData = response.data[0];
        this.cdref.detectChanges();
      } else {
        this.items = [];
        this.cdref.detectChanges();
        this.toastr.error(response.message);
      }
    })
  }


  getFiles() {
    this.spinner.show();
    this.extranetService.getExtranetFiles('Member', this.userControlId).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        this.items = [];
        this.cdref.detectChanges();
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {

      if (response.statusCode == 200) {
        this.items = [];
        response.data.children?.forEach(element => {
          let treeviewItem = new TreeviewItem(element)
          treeviewItem.setCollapsedRecursive(true);
          this.items.push(treeviewItem);
        });
        this.spinner.hide();
        this.cdref.detectChanges();
      } else {
        this.items = [];
        this.spinner.hide();
        this.cdref.detectChanges();
        this.toastr.error(response.message);
      }
    })
  }

  onSubmit() {
    let fileType = this.f.fileType.value;
    this.spinner.show();
    this.extranetService.uploadExtranetFile(this.file, fileType).pipe(
      catchError((error: any) => {
        this.spinner.hide();
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      this.spinner.hide();
      if (response.statusCode == 200) {
        this.toastr.success("File Uploaded Successfully");
        this.getFiles();
      }
      else {
        this.toastr.error(response.message);
      }
    }
    )
  }

  downloadFile(fileItem) {
    let path = {
      "filepath": fileItem.value.filePath
    }
    this.extranetService.getFileDownloadable(path).pipe(
      catchError((error: any) => {
        this.toastr.error(this.config.failureResponse.SOMETHING_WENT_WRONG);
        if (error.status != 200) {
          return throwError(error.error);
        }
      })
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        let file = response.data[0];
        let contentType = 'text/csv';
        let blob = base64StringToBlob(file, contentType);
        saveAs(blob, fileItem.text, {
          autoBOM: true
        })
        this.toastr.success(response.message);
      } else {
        this.toastr.error(response.message);
      }
    })

  }

  dragable() {
    $("#draggable").dragable();
  }

  clearSearch() {
    this.treeviewComponent.onFilterTextChange('')
    this.treeviewComponent.filterText = '';
    this.items = [...this.items];
  }
}
